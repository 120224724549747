var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', _vm._g(_vm._b({
    staticClass: "hover:bg-gray-100 rounded-lg flex justify-center items-center transition-all transform scale-100 active:scale-90",
    staticStyle: {
      "height": "30px",
      "width": "30px"
    }
  }, 'button', _vm.$attrs, false), _vm.$listeners), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }