var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PopupMenuNotificationWrapper', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hasUnreadNotification = _ref.hasUnreadNotification;
        return [_c('ButtonIcon', {
          staticClass: "p-1",
          staticStyle: {
            "height": "100%",
            "width": "100%"
          }
        }, [_c('div', {
          staticClass: "flex flex-col"
        }, [_c('BadgeUnread', {
          attrs: {
            "show": hasUnreadNotification
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": _vm.iconNotification,
            "height": "25",
            "width": "25",
            "fill": "#008C81"
          }
        })], 1), _c('p', {
          staticClass: "text-dgreen font-secondary text-sm font-normal"
        }, [_vm._v(" Notifikasi ")])], 1)])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }