<template>
  <c-image
    alt="logo"
    :h="['30px', '40px']"
    :src="'https://ik.imagekit.io/dietela/pwa_webp/landing_page/dietela_logo.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675826444039'"
  />
</template>

<script>
export default {
  name: 'LogoColored',
}
</script>
