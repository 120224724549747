<template>
  <PopupMenuNotificationWrapper v-slot="{hasUnreadNotification}">
    <ButtonIcon
      class="p-1"
      style="height: 100%; width: 100%;"
    >
      <div class="flex flex-col">
        <BadgeUnread :show="hasUnreadNotification">
          <inline-svg
            :src="iconNotification"
            height="25"
            width="25"
            fill="#008C81"
          />
        </BadgeUnread>
        <p class="text-dgreen font-secondary text-sm font-normal">
          Notifikasi
        </p>
      </div>
    </ButtonIcon>
  </PopupMenuNotificationWrapper>
</template>

<script >
import PopupMenuNotificationWrapper from './elements/popup-menu-notification-wrapper.vue';
import ButtonIcon from './elements/button-icon.vue';
import BadgeUnread from './elements/badge-unread.vue';
import iconNotification from '@/assets/icons/icon-notification.svg';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  return {
    iconNotification
  };
};

__sfc_main.components = Object.assign({
  PopupMenuNotificationWrapper,
  ButtonIcon,
  BadgeUnread
}, __sfc_main.components);
export default __sfc_main;
</script>
