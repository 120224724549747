var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isAuthenticated && _vm.role === 'nutritionist' ? _c('c-list', {
    attrs: {
      "d": "flex",
      "align-items": "center",
      "gap": "32px"
    }
  }, [_c('header-notification'), _c('header-user')], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }