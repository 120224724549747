<template>
  <c-menu
    v-slot="{ isOpen }"
    pos="relative"
    z-index="99"
  >
    <c-menu-button
      v-chakra="{
        ':hover': {
          backgroundColor: 'transparent',
        },
        ':focus': {
          outline: 'none',
          boxShadow: 'none',
          background: 'none !important',
        },
      }"
      display="flex"
      background-color="transparent"
      flex-direction="row"
      gap="2"
      justify-content="space-between"
      w="100%"
      text-align="center"
      h="auto"
      :is-active="isOpen"
      :border-top="['0.5px solid #C4C4C4','none']"
      :padding="['10px','0']"
      border-radius="0"
      @click="redirect"
    >
      <c-text
        font-family="Roboto"
        :font-size="['14px', '16px']"
        line-height="18px"
        font-weight="400"
        :color="['#000000', '#008C81']"
      >
        Blog
      </c-text>
      <c-image
        :src="require('@/assets/icon-blog.svg')"
        mb="5px"
        :display="['block', 'none']"
      />
    </c-menu-button>
  </c-menu>
</template>

<script>
export default {
  name: 'HeaderBlog',
  props: {
    role: {
      type: String,
      default: '',
    },
  },
  methods: {
    redirect() {
      this.$emit('on-close')
      this.$router.push({ name: 'client.blog' })
    },
  },
}
</script>
