var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-image', {
    attrs: {
      "alt": "logo",
      "h": ['30px', '40px'],
      "src": 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/dietela_logo.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675826444039'
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }