<template>
  <c-menu
    v-slot="{ isOpen }"
    pos="relative"
    w="100%"
    z-index="99"
  >
    <c-menu-button
      v-chakra="{
        ':hover': {
          backgroundColor: 'transparent',
        },
        ':focus': {
          outline: 'none',
          boxShadow: 'none',
          background: 'none !important',
        },
      }"
      as="a"
      :href="LINK_DIETELA_SHOP"
      :target="target"
      display="flex"
      background-color="transparent"
      justify-content="space-between"
      flex-direction="row"
      gap="2"
      text-align="center"
      h="auto"
      w="100%"
      :is-active="isOpen"
      :border-top="['0.5px solid #C4C4C4','none']"
      :padding="['10px','0']"
      border-radius="0"
    >
      <c-text
        font-family="Roboto"
        :font-size="['14px', '16px']"
        line-height="18px"
        font-weight="400"
        :color="['#000000', '#008C81']"
      >
        Toko
      </c-text>
      <c-image
        :src="require('@/assets/icon-store.svg')"
        mb="5px"
        :display="['block', 'none']"
      />
    </c-menu-button>
  </c-menu>
</template>

<script>
import { LINK_DIETELA_SHOP } from '@/constants/generals'

export default {
  name: 'HeaderStore',
  props: {
    target: {
      type: String,
      default: '_self',
    },
  },
  computed: {
    LINK_DIETELA_SHOP() {
      return LINK_DIETELA_SHOP
    },
  },
}
</script>
