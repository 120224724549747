<template>
  <fragment>
    <c-box
      v-for="(nav, index) in props.listNavigation"
      :key="index"
      :mb="(index+1) == props.listNavigation.length ? '0' : '1rem'"
    >
      <c-text 
        :font-size="['16px', '20px']" 
        :font-weight="['500', '700']"
        :mb="['0px', '8px']"
        color="#555"
      >
        {{ nav.categories }}
      </c-text>
      <c-list
        style-type="none"
        :spacing="['0', '2']"
      >
        <c-list-item 
          v-for="(link, index_) in nav.list"
          :key="index_"
          color="#555"
        >
          <c-box
            v-if="!link.url && nav.categories == 'Customer Care'"
            :font-size="['12px', '16px']"
            d="flex"
            :flex-direction="['column', 'row']"
            min-h="20px"
            line-height="1rem"
          >
            {{ getLabel(link.list) }}
            <!-- {{ link.list }} -->
            <c-box 
              v-if="getHours(link.list)"
              :ml="['0px', '8px']"
            >
              {{ getHours(link.list) }}
            </c-box>
          </c-box>
          <c-box 
            v-else 
            v-chakra="{
              ':hover': {
                textDecoration: 'underline',
              }
            }"
            as="a"
            :href="link.url ? link.url : undefined"
            :font-size="['12px', '16px']"
            min-h="24px"
            cursor="pointer"
          >
            {{ link.list }}
          </c-box>
        </c-list-item>
      </c-list>
    </c-box>
    <!-- <div v-for="(nav, index) in props.listNavigation" :key="index" class="text-dgray not-last:mb-4">
    </div> -->
  </fragment>
</template>



<script >
import { Fragment } from 'vue-fragment';
const __sfc_main = {
  name: 'ItemNavigation'
};
__sfc_main.props = {
  listNavigation: {
    type: Array,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  const getLabel = data => {
    if (data.includes(':')) {
      return data.split(/[:]+/)[0] + ':';
    }

    return data;
  };

  const getHours = data => {
    if (data.includes(':')) {
      return data.split(/[:]+/).pop();
    }

    return false;
  };

  return {
    props,
    getLabel,
    getHours
  };
};

__sfc_main.components = Object.assign({
  Fragment
}, __sfc_main.components);
export default __sfc_main;
</script>