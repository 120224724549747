var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "background-color": "#C7F9E3",
      "gap": "8px",
      "align-items": "center",
      "padding": ['2px 10px', '10px'],
      "border-radius": "8px",
      "box-shadow": "0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset",
      "width": "fit-content"
    }
  }, [_c('c-image', {
    attrs: {
      "height": ['20px', '40px'],
      "width": ['20px', '40px'],
      "src": require('@/assets/icons/icon-poin.png')
    }
  }), _c('BaseText', {
    attrs: {
      "size-desktop": "20px",
      "size-mobile": "18px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }