var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          backgroundColor: '#E2E8F0'
        }
      },
      expression: "{\n      ':hover': {\n        backgroundColor: '#E2E8F0',\n      },\n    }"
    }],
    attrs: {
      "width": "100%",
      "as": "button",
      "display": "flex",
      "background-color": "transparent",
      "flex-direction": "row",
      "justify-content": "space-between",
      "w": "100%",
      "gap": "2",
      "text-align": "center",
      "h": "auto",
      "border-top": ['0.5px solid #C4C4C4', 'none'],
      "padding": ['10px', '0'],
      "border-radius": "0"
    },
    on: {
      "click": _vm.redirect
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "line-height": "18px",
      "font-weight": "400",
      "color": ['#000000', '#008C81']
    }
  }, [_vm._v(" Harga ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/icon-rupiah.svg'),
      "mb": "5px",
      "display": ['block', 'none']
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }