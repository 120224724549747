<template>
  <router-link :to="{name: 'client.cart'}">
    <ButtonIcon
      class="p-1 h-full"
      style="height: 100%; width: 100%;"
    >
      <div style="display: flex; flex-direction: column; align-items: center;">
        <BadgeUnread :show="getItemsQuantity">
          <inline-svg
            :src="iconCart"
            height="25"
            width="25"
            fill="#008C81"
          />
        </BadgeUnread>
        <p class="text-dgreen font-secondary text-sm font-normal">
          Keranjang
        </p>
      </div>
    </ButtonIcon>
  </router-link>
</template>

<script>
import iconCart from '@/assets/icons/icon-cart.svg'
import ButtonIcon from './elements/button-icon.vue'
import { mapGetters } from 'vuex'
import BadgeUnread from './elements/badge-unread.vue'

export default {
  components: {
    ButtonIcon,
    BadgeUnread,
  },
  data() {
    return {
      iconCart,
    }
  },
  computed: {
    ...mapGetters({
      getItemsQuantity: 'cart/getItemsQuantity',
    }),
  },

}
</script>
