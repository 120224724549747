var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative",
      "z-index": "99",
      "width": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "display": "flex",
      "justify-content": "space-between",
      "text-align": "center",
      "border-top": ['0.5px solid #C4C4C4', 'none']
    }
  }, [_c('c-accordion', {
    attrs: {
      "display": ['block', 'none'],
      "allow-multiple": true,
      "width": "100%"
    }
  }, [_c('c-accordion-item', {
    attrs: {
      "width": "100%",
      "border": "none"
    }
  }, [_c('c-accordion-header', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          backgroundColor: '#E2E8F0'
        }
      },
      expression: "{\n            ':hover': {\n              backgroundColor: '#E2E8F0',\n            },\n          }"
    }],
    attrs: {
      "id": "asddasdsad header",
      "width": "100%",
      "padding": "10px"
    }
  }, [_c('c-box', {
    attrs: {
      "text-align": "left",
      "width": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "line-height": "18px",
      "font-weight": "400",
      "color": ['#000000', '#008C81'],
      "display": ['block', 'none']
    }
  }, [_vm._v(" Tentang Kami ")])], 1), _c('c-image', {
    attrs: {
      "src": require('@/assets/icon-info.svg'),
      "mb": "5px",
      "display": ['block', 'none']
    }
  })], 1), _c('c-accordion-panel', {
    attrs: {
      "padding-bottom": "0",
      "padding-top": "0"
    }
  }, _vm._l(_vm.menus, function (menu, index) {
    return _c('c-box', {
      key: index
    }, [_c('c-box', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            backgroundColor: '#E2E8F0'
          }
        },
        expression: "{\n                ':hover': {\n                  backgroundColor: '#E2E8F0',\n                },\n              }"
      }],
      attrs: {
        "as": "button",
        "width": "100%",
        "padding": "8px 0",
        "text-align": "start"
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickMenuItem(menu);
        }
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "12px",
        "size-desktop": "14px"
      }
    }, [_vm._v(" " + _vm._s(menu.name) + " ")])], 1), index !== _vm.menus.length - 1 ? _c('BaseDivider', {
      attrs: {
        "margin": "0",
        "opacity": "30%"
      }
    }) : _vm._e()], 1);
  }), 1)], 1)], 1), _c('c-popover', {
    attrs: {
      "trigger": "hover",
      "placement": "bottom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var onClose = _ref.onClose;
        return [_c('c-popover-trigger', [_c('c-box', {
          attrs: {
            "height": "44px",
            "display": "flex",
            "align-items": "center"
          }
        }, [_c('c-text', {
          attrs: {
            "font-family": "Roboto",
            "font-size": ['14px', '16px'],
            "line-height": "18px",
            "font-weight": "400",
            "color": ['#000000', '#008C81'],
            "display": ['none', 'block']
          }
        }, [_vm._v(" Tentang Kami ")])], 1)], 1), _c('c-popover-content', {
          attrs: {
            "border": "0",
            "z-index": "4",
            "width": "150px",
            "border-radius": "12px",
            "gutter": "16",
            "overflow": "hidden"
          }
        }, _vm._l(_vm.menus, function (menu, index) {
          return _c('c-box', {
            key: index
          }, [_c('c-box', {
            directives: [{
              name: "chakra",
              rawName: "v-chakra",
              value: {
                ':hover': {
                  backgroundColor: '#E2E8F0'
                }
              },
              expression: "{\n              ':hover': {\n                backgroundColor: '#E2E8F0',\n              },\n            }"
            }],
            attrs: {
              "as": "button",
              "width": "100%",
              "padding": "14px",
              "text-align": "center"
            },
            on: {
              "click": function click($event) {
                return _vm.handleClickMenuItem(menu, onClose);
              }
            }
          }, [_vm._v(" " + _vm._s(menu.name) + " ")]), index !== _vm.menus.length - 1 ? _c('BaseDivider', {
            attrs: {
              "margin": "0",
              "opacity": "20%"
            }
          }) : _vm._e()], 1);
        }), 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }