var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-menu', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "z-index": "99"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var isOpen = _ref.isOpen;
        return [_c('c-menu-button', {
          directives: [{
            name: "chakra",
            rawName: "v-chakra",
            value: {
              ':hover': {
                backgroundColor: 'transparent'
              },
              ':focus': {
                outline: 'none',
                boxShadow: 'none',
                background: 'none !important'
              }
            },
            expression: "{\n      ':hover': {\n        backgroundColor: 'transparent',\n      },\n      ':focus': {\n        outline: 'none',\n        boxShadow: 'none',\n        background: 'none !important',\n      },\n    }"
          }],
          attrs: {
            "as": "a",
            "href": _vm.LINK_DIETELA_SHOP,
            "target": _vm.target,
            "display": "flex",
            "background-color": "transparent",
            "justify-content": "space-between",
            "flex-direction": "row",
            "gap": "2",
            "text-align": "center",
            "h": "auto",
            "w": "100%",
            "is-active": isOpen,
            "border-top": ['0.5px solid #C4C4C4', 'none'],
            "padding": ['10px', '0'],
            "border-radius": "0"
          }
        }, [_c('c-text', {
          attrs: {
            "font-family": "Roboto",
            "font-size": ['14px', '16px'],
            "line-height": "18px",
            "font-weight": "400",
            "color": ['#000000', '#008C81']
          }
        }, [_vm._v(" Toko ")]), _c('c-image', {
          attrs: {
            "src": require('@/assets/icon-store.svg'),
            "mb": "5px",
            "display": ['block', 'none']
          }
        })], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }