import { render, staticRenderFns } from "./header-program.vue?vue&type=template&id=1a892a91&"
import script from "./header-program.vue?vue&type=script&lang=js&"
export * from "./header-program.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CImage: require('@chakra-ui/vue').CImage, CAccordionHeader: require('@chakra-ui/vue').CAccordionHeader, CAccordionPanel: require('@chakra-ui/vue').CAccordionPanel, CAccordionItem: require('@chakra-ui/vue').CAccordionItem, CAccordion: require('@chakra-ui/vue').CAccordion, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover})
