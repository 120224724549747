<template>
  <c-link
    as="router-link"
    :to="{name: 'auth', query: { tab: 'login' }}"
    px="28px"
    py="8px"
    color="white"
    bg-color="#008C81"
    border-color="#008C81"
    border="2px"
    border-radius="100px"
    :font-size="['14px', '16px']"
    font-weight="500"
    font-family="Roboto"
    display="flex"
    flex-direction="row"
    gap="2"
    w="100%"
    justify-content="center"
    data-testid="header-login"
  >
    Masuk
    <c-image :src="require('@/assets/icon-login.svg')" />
  </c-link>
</template>

<script>
export default {
  name: 'HeaderLogin',
  props: {
    role: {
      type: String,
      default: '',
    },
  },
}
</script>
