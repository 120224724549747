var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('fragment', _vm._l(_vm.props.listNavigation, function (nav, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "mb": index + 1 == _vm.props.listNavigation.length ? '0' : '1rem'
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['16px', '20px'],
        "font-weight": ['500', '700'],
        "mb": ['0px', '8px'],
        "color": "#555"
      }
    }, [_vm._v(" " + _vm._s(nav.categories) + " ")]), _c('c-list', {
      attrs: {
        "style-type": "none",
        "spacing": ['0', '2']
      }
    }, _vm._l(nav.list, function (link, index_) {
      return _c('c-list-item', {
        key: index_,
        attrs: {
          "color": "#555"
        }
      }, [!link.url && nav.categories == 'Customer Care' ? _c('c-box', {
        attrs: {
          "font-size": ['12px', '16px'],
          "d": "flex",
          "flex-direction": ['column', 'row'],
          "min-h": "20px",
          "line-height": "1rem"
        }
      }, [_vm._v(" " + _vm._s(_vm.getLabel(link.list)) + " "), _vm.getHours(link.list) ? _c('c-box', {
        attrs: {
          "ml": ['0px', '8px']
        }
      }, [_vm._v(" " + _vm._s(_vm.getHours(link.list)) + " ")]) : _vm._e()], 1) : _c('c-box', {
        directives: [{
          name: "chakra",
          rawName: "v-chakra",
          value: {
            ':hover': {
              textDecoration: 'underline'
            }
          },
          expression: "{\n            ':hover': {\n              textDecoration: 'underline',\n            }\n          }"
        }],
        attrs: {
          "as": "a",
          "href": link.url ? link.url : undefined,
          "font-size": ['12px', '16px'],
          "min-h": "24px",
          "cursor": "pointer"
        }
      }, [_vm._v(" " + _vm._s(link.list) + " ")])], 1);
    }), 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }