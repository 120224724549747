<template>
  <c-box display="flex">
    <c-menu
      pos="relative"
      :is-open="false"
    >
      <c-menu-button
        v-chakra="{
          ':hover': {
            backgroundColor: 'transparent',
          },
          ':focus': {
            outline: 'none',
            boxShadow: 'none !important',
            background: 'none !important',
          },
        }"
        display="flex"
        background-color="transparent"
        flex-direction="column"
        text-align="center"
        h="auto"
        variant="ghost"
        @click="isOpen = true"
      >
        <c-image
          :src="require('@/assets/icon-hamburger.svg')"
          mx="auto"
          my="7.5px"
          h="15px"
        />
      </c-menu-button>
      <div
        id="overlay"
        ref="overlay"
        :style="{ display: [isOpen ? 'block' : 'none'] }"
        @click="onClose"
      />
      <c-menu-list
        ml="-5px !important"
        mt="5px !important"
        w="70%"
        box-shadow="0px 5px 30px 0px #00000026"
        border="none"
        z-index="999"
        border-radius="12px"
      >
        <c-menu-item
          ref="overlay2"
          display="none"
        />
        <c-flex
          justify-content="space-between"
          align-items="center"
          p="25px"
          grid-gap="15px"
          w="100%"
          flex-direction="column"
        >
          <HeaderBeranda />
          <HeaderTentangKami @on-close="onClose" />
          <HeaderProgram
            :list-menu-program="listMenuProgram"
            @on-close="onClose"
          />
          <HeaderPricing @on-close="onClose" />
          <HeaderBlog @on-close="onClose" />
          <header-store />
          <HeaderLogin />
          <!--          <HeaderRegister />-->
        </c-flex>
      </c-menu-list>
    </c-menu>
  </c-box>
</template>

<script>
import HeaderBeranda from './header-beranda.vue'
import HeaderTentangKami from './header-tentang-kami.vue'
import HeaderProgram from './header-program.vue'
import HeaderBlog from './header-blog.vue'
import HeaderPricing from './header-pricing.vue'
import HeaderLogin from './header-login.vue'
// import HeaderRegister from './header-register.vue'
import HeaderStore from './header-store.vue'
// import HeaderHamburger from './header-hamburger.vue'

export default {
  name: 'HeaderHamburger',
  components: {
    HeaderBeranda,
    HeaderTentangKami,
    HeaderProgram,
    HeaderBlog,
    HeaderPricing,
    HeaderLogin,
    // HeaderRegister,
    HeaderStore,
    // HeaderHamburger
  },
  props: {
    role: {
      type: String,
      default: '',
    },
    listMenuProgram: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    onClose() {
      this.isOpen = false
      this.$refs.overlay?.click()
      this.$refs.overlay2?.$el?.click()
    },
  },
}
</script>
<style>
#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.5;
    z-index: 2;
    cursor: pointer;
}
</style>
