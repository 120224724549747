var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "display": "flex"
    }
  }, [_c('c-menu', {
    attrs: {
      "pos": "relative",
      "is-open": false
    }
  }, [_c('c-menu-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          backgroundColor: 'transparent'
        },
        ':focus': {
          outline: 'none',
          boxShadow: 'none !important',
          background: 'none !important'
        }
      },
      expression: "{\n        ':hover': {\n          backgroundColor: 'transparent',\n        },\n        ':focus': {\n          outline: 'none',\n          boxShadow: 'none !important',\n          background: 'none !important',\n        },\n      }"
    }],
    attrs: {
      "display": "flex",
      "background-color": "transparent",
      "flex-direction": "column",
      "text-align": "center",
      "h": "auto",
      "variant": "ghost"
    },
    on: {
      "click": function click($event) {
        _vm.isOpen = true;
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-hamburger.svg'),
      "mx": "auto",
      "my": "7.5px",
      "h": "15px"
    }
  })], 1), _c('div', {
    ref: "overlay",
    style: {
      display: [_vm.isOpen ? 'block' : 'none']
    },
    attrs: {
      "id": "overlay"
    },
    on: {
      "click": _vm.onClose
    }
  }), _c('c-menu-list', {
    attrs: {
      "ml": "-5px !important",
      "mt": "5px !important",
      "w": "70%",
      "box-shadow": "0px 5px 30px 0px #00000026",
      "border": "none",
      "z-index": "999",
      "border-radius": "12px"
    }
  }, [_c('c-menu-item', {
    ref: "overlay2",
    attrs: {
      "display": "none"
    }
  }), _c('c-flex', {
    attrs: {
      "justify-content": "space-between",
      "align-items": "center",
      "p": "25px",
      "grid-gap": "15px",
      "w": "100%",
      "flex-direction": "column"
    }
  }, [_c('HeaderBeranda'), _c('HeaderTentangKami', {
    on: {
      "on-close": _vm.onClose
    }
  }), _c('HeaderProgram', {
    attrs: {
      "list-menu-program": _vm.listMenuProgram
    },
    on: {
      "on-close": _vm.onClose
    }
  }), _c('HeaderPricing', {
    on: {
      "on-close": _vm.onClose
    }
  }), _c('HeaderBlog', {
    on: {
      "on-close": _vm.onClose
    }
  }), _c('header-store'), _c('HeaderLogin')], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }