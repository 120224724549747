import iconCircleInstagram from '@/assets/icons/icon-circle-instagram.svg'
import iconCircleFacebook from '@/assets/icons/icon-circle-facebook.svg'
import iconCircleTwitter from '@/assets/icons/icon-circle-twitter.svg'

export const listFooterSocialMedia = [
  {
    label: 'Dietela Instagram',
    url: 'https://www.instagram.com/dietela.id',
    iconSvg: iconCircleInstagram,
  },
  {
    label: 'Dietela Facebook',
    url: 'https://facebook.com/dietelaindonesia',
    iconSvg: iconCircleFacebook,
  },
  {
    label: 'Dietela Twitter',
    url: 'https://twitter.com/dietela_id',
    iconSvg: iconCircleTwitter,
  },
]

export const listFooterPaymentMethod = [
  {
    label: 'Bank BNI',
    url: '#',
    icon: 'https://ik.imagekit.io/dietela/pwa_webp/footer/bni.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1676455565961',
    height: ['17px', '30px'],
  },
  {
    label: 'Bank Mandiri',
    url: '#',
    icon: 'https://ik.imagekit.io/dietela/pwa_webp/footer/mandiri.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1676455566159',
    height: ['17px', '30px'],
  },
  {
    label: 'Bank Permata',
    url: '#',
    icon: 'https://ik.imagekit.io/dietela/pwa_webp/footer/permata_bank.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1676455566074',
    height: ['17px', '30px'],
  },
  {
    label: 'PRIMA',
    url: '#',
    icon: 'https://ik.imagekit.io/dietela/pwa_webp/footer/bank_transfer_network_prima.webp?updatedAt=1693888863576',
    height: ['18px', '30px'],
  },
  {
    label: 'ATM Bersama',
    url: '#',
    icon: 'https://ik.imagekit.io/dietela/pwa_webp/footer/bank_transfer_network_atm_bersama.webp?updatedAt=1693888863420',
    height: ['18px', '30px'],
  },
  {
    label: 'Alto',
    url: '#',
    icon: 'https://ik.imagekit.io/dietela/pwa_webp/footer/bank_transfer_network_alto.webp?updatedAt=1693888863655',
    height: ['18px', '30px'],
  },
  {
    label: 'Master Card',
    url: '#',
    icon: 'https://ik.imagekit.io/dietela/pwa_webp/footer/mastercard.webp?updatedAt=1693888863562',
    height: ['25px', '35px'],
  },
  { label: 'Visa', url: '#', icon: 'https://ik.imagekit.io/dietela/pwa_webp/footer/visa.webp?updatedAt=1693888863544' },
  {
    label: 'QRIS',
    url: '#',
    icon: 'https://ik.imagekit.io/dietela/pwa_webp/footer/qris_logo.webp?updatedAt=1693888863459',
    height: ['30px', '50px'],
  },
  {
    label: 'Gopay',
    url: '#',
    icon: 'https://ik.imagekit.io/dietela/pwa_webp/footer/gopay_landscape.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1676456936533',
    height: ['17px', '28px'],
  },
]
