var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-menu', {
    attrs: {
      "pos": "relative",
      "z-index": "99"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var isOpen = _ref.isOpen;
        return [_c('c-menu-button', {
          directives: [{
            name: "chakra",
            rawName: "v-chakra",
            value: {
              ':hover': {
                backgroundColor: 'transparent'
              },
              ':focus': {
                boxShadow: 'none'
              }
            },
            expression: "{\n      ':hover': {\n        backgroundColor: 'transparent',\n      },\n      ':focus': {\n        boxShadow: 'none'\n      },\n    }"
          }],
          attrs: {
            "as": "span",
            "is-active": isOpen,
            "outline-offset": "0",
            "min-width": "0",
            "p": "0",
            "h": "100%",
            "bg": "transparent"
          }
        }, [_vm._t("default", function () {
          return [_c('ButtonIcon', [_c('BadgeUnread', {
            attrs: {
              "show": _vm.hasUnreadNotification
            }
          }, [_c('inline-svg', {
            attrs: {
              "src": _vm.iconNotification,
              "height": "19",
              "width": "19",
              "fill": "#323232"
            }
          })], 1)], 1)];
        }, {
          "hasUnreadNotification": _vm.hasUnreadNotification
        })], 2), _c('c-menu-list', {
          attrs: {
            "bg": "transparent",
            "p": "0",
            "box-shadow": "none",
            "border": "none",
            "font-size": "0.875rem",
            "line-height": "1.25rem"
          }
        }, [_c('c-box', {
          attrs: {
            "bg": "#FFF",
            "border-radius": "12px",
            "w": "100%",
            "overflow": "hidden",
            "border": "1px solid rgba(0,0,0,0.05)",
            "box-shadow": "0 4px 6px -1px rgba(0, 0, 0, 0.1)"
          }
        }, [_c('c-box', {
          attrs: {
            "p": "0.5rem"
          }
        }, [_c('c-text', {
          attrs: {
            "font-size": "1rem",
            "color": "#008C81",
            "text-align": "center",
            "font-weight": "500"
          }
        }, [_vm._v(" Notifikasi ")])], 1), _c('c-box', {
          attrs: {
            "as": "hr",
            "w": "100%",
            "border-top": "1px solid #0000001A"
          }
        }), _c('NotificationItem', {
          staticClass: "no-scrollbar",
          staticStyle: {
            "max-height": "50vh",
            "overflow-x": "auto"
          },
          attrs: {
            "notifications": _vm.notificationData
          },
          on: {
            "onClick": _vm.handleClickNotification
          }
        }), _c('c-box', {
          attrs: {
            "as": "hr",
            "w": "100%",
            "border-top": "1px solid #0000001A"
          }
        }), _c('c-menu-item', {
          directives: [{
            name: "chakra",
            rawName: "v-chakra",
            value: {
              ':active': {
                bg: '#F2F2F2'
              }
            },
            expression: "{\n          ':active': {\n            bg: '#F2F2F2'\n          } \n        }"
          }],
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "w": "100%",
            "px": "1rem",
            "pt": "0.5rem",
            "pb": "1rem",
            "bg": "#FFF",
            "font-size": ['14px', '16px']
          },
          on: {
            "click": _vm.handleReadAll
          }
        }, [_vm._v(" Lihat semua ")])], 1)], 1), _c('ModalInfo', {
          attrs: {
            "for": "infoNotitifcation",
            "close-on-overlay-click": false,
            "is-open": _vm.isOpenModal,
            "image": _vm.modalNotification.image,
            "text": _vm.modalNotification.text,
            "button-text": _vm.modalNotification.buttonText,
            "button-link": _vm.modalNotification.buttonLink,
            "with-button-close": _vm.modalNotification.buttonClose
          },
          on: {
            "close": _vm.handleCloseSuccessModal
          }
        })];
      }
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }