<template>
  <c-box
    pos="relative"
    z-index="99"
    width="100%"
  >
    <c-box
      width="100%"
      display="flex"
      justify-content="space-between"
      text-align="center"
      :border-top="['0.5px solid #C4C4C4','none']"
    >
      <!--      only show mobile design-->
      <c-accordion
        :display="['block', 'none']"
        :allow-multiple="true"
        width="100%"
      >
        <c-accordion-item
          width="100%"
          border="none"
        >
          <c-accordion-header
            id="asddasdsad header"
            v-chakra="{
              ':hover': {
                backgroundColor: '#E2E8F0',
              },
            }"
            width="100%"
            padding="10px"
          >
            <c-box
              text-align="left"
              width="100%"
            >
              <c-text
                font-family="Roboto"
                :font-size="['14px', '16px']"
                line-height="18px"
                font-weight="400"
                :color="['#000000', '#008C81']"
                :display="['block', 'none']"
              >
                Tentang Kami
              </c-text>
            </c-box>
            <c-image
              :src="require('@/assets/icon-info.svg')"
              mb="5px"
              :display="['block', 'none']"
            />
          </c-accordion-header>
          <c-accordion-panel
            padding-bottom="0"
            padding-top="0"
          >
            <c-box
              v-for="(menu, index) in menus"
              :key="index"
            >
              <c-box
                v-chakra="{
                  ':hover': {
                    backgroundColor: '#E2E8F0',
                  },
                }"
                as="button"
                width="100%"
                padding="8px 0"
                text-align="start"
                @click="handleClickMenuItem(menu)"
              >
                <BaseText
                  size-mobile="12px"
                  size-desktop="14px"
                >
                  {{ menu.name }}
                </BaseText>
              </c-box>

              <BaseDivider
                v-if="index !== menus.length-1"
                margin="0"
                opacity="30%"
              />
            </c-box>
          </c-accordion-panel>
        </c-accordion-item>
      </c-accordion>

      <!-- only show desktop design -->
      <c-popover
        v-slot="{ onClose }"
        trigger="hover"
        placement="bottom"
      >
        <c-popover-trigger>
          <c-box
            height="44px"
            display="flex"
            align-items="center"
          >
            <c-text
              font-family="Roboto"
              :font-size="['14px', '16px']"
              line-height="18px"
              font-weight="400"
              :color="['#000000', '#008C81']"
              :display="['none', 'block']"
            >
              Tentang Kami
            </c-text>
          </c-box>
        </c-popover-trigger>
        <c-popover-content
          border="0"
          z-index="4"
          width="150px"
          border-radius="12px"
          gutter="16"
          overflow="hidden"
        >
          <c-box
            v-for="(menu, index) in menus"
            :key="index"
          >
            <c-box
              v-chakra="{
                ':hover': {
                  backgroundColor: '#E2E8F0',
                },
              }"
              as="button"
              width="100%"
              padding="14px"
              text-align="center"
              @click="handleClickMenuItem(menu, onClose)"
            >
              {{ menu.name }}
            </c-box>

            <BaseDivider
              v-if="index !== menus.length-1"
              margin="0"
              opacity="20%"
            />
          </c-box>
        </c-popover-content>
      </c-popover>
    </c-box>
  </c-box>
</template>

<script>
import BaseText from '@/components/elements/base-text.vue'
import BaseDivider from '@/components/elements/base-divider.vue'

export default {
  name: 'HeaderTentangKami',
  components: {
    BaseDivider,
    BaseText,
  },
  props: {
    role: {
      type: String,
      default: '',
    },
  },
  computed: {
    menus() {
      return [
        {
          name: 'Tentang Dietela',
          path: '/tentang-dietela',
          newTab: false,
        },
        // {
        //   name: 'Ahli Gizi',
        //   path: '/ahli-gizi',
        //   newTab: false,
        // },
      ]
    },
  },
  methods: {
    onCloseHamburger() {
      this.$emit('on-close')
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    handleClickMenuItem(item, onClose) {
      if (onClose) onClose()
      this.onCloseHamburger()

      const isSamePath = this.$route.path == item.path
      if (isSamePath) {
        this.scrollToTop()
        return
      }

      if (item.newTab) {
        const routeData = this.$router.resolve(item.path)
        window.open(routeData.href, '_blank')
      } else {
        this.$router.push(item.path)
      }
    },
  },
}
</script>
