var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-link', {
    attrs: {
      "as": "router-link",
      "to": {
        name: 'auth',
        query: {
          tab: 'login'
        }
      },
      "px": "28px",
      "py": "8px",
      "color": "white",
      "bg-color": "#008C81",
      "border-color": "#008C81",
      "border": "2px",
      "border-radius": "100px",
      "font-size": ['14px', '16px'],
      "font-weight": "500",
      "font-family": "Roboto",
      "display": "flex",
      "flex-direction": "row",
      "gap": "2",
      "w": "100%",
      "justify-content": "center",
      "data-testid": "header-login"
    }
  }, [_vm._v(" Masuk "), _c('c-image', {
    attrs: {
      "src": require('@/assets/icon-login.svg')
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }