<template>
  <c-flex
    background-color="#C7F9E3"
    gap="8px"
    align-items="center"
    :padding="['2px 10px', '10px']"
    border-radius="8px"
    box-shadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset"
    width="fit-content"
  >
    <c-image
      :height="['20px', '40px']"
      :width="['20px', '40px']"
      :src="require('@/assets/icons/icon-poin.png')"
    />
    <BaseText
      size-desktop="20px"
      size-mobile="18px"
    >
      {{ value }}
    </BaseText>
  </c-flex>
</template>

<script>
import { CFlex, CImage } from '@chakra-ui/vue'
import BaseText from '@/components/elements/base-text.vue'

export default {
  name: 'UserPoin',
  components: {
    BaseText,
    CFlex,
    CImage,
  },
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
  },
}
</script>
