<template>
  <c-list
    v-if="isAuthenticated && role === 'nutritionist'"
    d="flex"
    align-items="center"
    gap="32px"
  >
    <header-notification />
    <header-user />
  </c-list>
</template>

<script>
import HeaderUser from './header-user.vue'
import HeaderNotification from '@/components/header/header-notification'

export default {
  name: 'HeaderMenusNutritionist',
  components: {
    HeaderUser,
    HeaderNotification,
  },
  props: {
    role: {
      type: String,
      default: '',
    },
    isAuthenticated: Boolean,
  },
}
</script>
